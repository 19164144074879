export const dashboardPermissionsToCheck = [
  "upcoming-appointments-dashboard",
  "next-birthdays-dashboard",
  "total-income-dashboard",
  "total-expense-dashboard",
  "appointments-by-doctors-chart-dashboard",
  "expense-categorization-chart-dashboard",
  "service-categorization-chart-dashboard",
  "rev-vs-exp-chart-dashboard",
];

export const doctorPermissionsToCheck = [
  "view-all-doctors",
  "add-new-doctor",
  "edit-doctor",
  "get-one-doctor",
  "delete-doctor",
  "change-active-status-doctor",
];

export const patientPermissionsToCheck = [
  "view-all-patients",
  "add-new-patient",
  "edit-patient",
  "delete-patient",
  "view-patient-details",
  "view-patient-wise-services",
  "get-all-patient-payment",
  "create-patient-payment",
];

export const referenceDoctorPermissionsToCheck = [
  "reference-doctors-list",
  "add-new-ref-doctor",
  "edit-ref-doctor",
  "delete-ref-doctor",
  "get-one-ref-doctor",
  "view-patient-by-ref-doctor",
  "change-active-status-ref-doctor",
  "view-payments-by-ref-doctor",
  "create-ref-doctor-payment",
];

export const treatmentPermissionsToCheck = [
  "view-all-treatments",
  "add-new-treatment",
  "edit-treatment",
  "delete-treatment",
];

export const appointmentsPermissionsToCheck = [
  "view-all-calendar-appointments",
  "add-new-appointment",
  "edit-appointment",
  "delete-appointment",
  "view-today-appointments",
  "view-all-appointments",
];

export const expensePermissionsToCheck = [
  "view-all-expenses",
  "add-new-expense",
  "edit-expense",
  "delete-expense",
];

export const tagsPermissionsToCheck = ["view-all-tags"];

export const paymentModesPermissionsToCheck = [
  "view-all-payment-modes",
  "add-new-payment-mode",
  "edit-payment-mode",
  "delete-payment-mode",
  "change-active-status-payment-mode",
];

export const expenseCategoryPermissionsToCheck = [
  "view-all-expense-categories",
  "add-new-expense-category",
  "get-one-expense-category",
  "edit-expense-category",
  "delete-expense-category",
];

export const servicePermissionsToCheck = [
  "view-all-services",
  "add-new-service",
  "edit-service",
  "get-one-service",
  "delete-service",
  "change-active-status-service",
];
