import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider } from "components/ui";
import useDarkMode from "utils/hooks/useDarkMode";
import { themeConfig } from "configs/theme.config";
import { fetchThemeConfig } from "store/theme/themeSlice";

const Theme = (props) => {
  const theme = useSelector((state) => state.theme);
  const [isDark] = useDarkMode();

  const currentTheme = {
    mode: isDark ? "dark" : "light",
    ...themeConfig,
    ...theme,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchThemeConfig());
  }, [dispatch]);

  return (
    <>
      <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    </>
  );
};

export default Theme;
