import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const FaviconUpdater = () => {
  // Access the favicon URL from the Redux store
  const faviconUrl = useSelector((state) => state?.favicon?.favicon?.favicon); // Adjust based on your state structure
  console.log("🚀 ~ FaviconUpdater ~ faviconUrl:", faviconUrl);

  useEffect(() => {
    if (faviconUrl) {
      // Create or update the favicon link tag
      let link = document.querySelector("link[rel*='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = faviconUrl; // Set the favicon URL from the Redux store
    }
  }, [faviconUrl]);

  return null; // This component doesn't render any UI
};

export default FaviconUpdater;
