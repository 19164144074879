import api from "./api/api";

export function login(data) {
  return api.post("admin/admin-login", data);
}

export function adminCount() {
  return api.get("admin/count");
}

export function register(data) {
  return api.post("admin/create-admin", data);
}

export function forgotPassword(data) {
  return api.post("admin/forgot-password", data);
}

export function resetPassword(data, token) {
  return api.put(`admin/password/${token}`, data);
}

export function loginUser(data) {
  return api.post("admin/user-login", data);
}

export function userCount() {
  return api.get("admin/user-count");
}

export function registerUser(data) {
  return api.post("admin/user/create", data);
}

export function forgotPasswordUser(data) {
  return api.post("admin/user-forgot-password", data);
}

export function resetPasswordUser(data, token) {
  return api.put(`admin/user-password/${token}`, data);
}

export function getSuperAdmin() {
  return api.get("admin/check-superadmin");
}