import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { loginUser } from "services/authServices";
import { useEffect, useState } from "react";
import {
  getPermissionChecks,
  getRedirectRoute,
} from "utils/helpers/common.helper";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const [route, setRoute] = useState(null);
  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  useEffect(() => {
    let routeName = localStorage.getItem("routeName");
    setRoute(routeName);
  }, []);

  const signIn = async (data) => {
    try {
      const resp = await loginUser(data);
      if (resp?.data?.data?.token) {
        const token = resp?.data?.data?.token;
        dispatch(setToken(token));
        dispatch(onSignInSuccess(token));

        if (resp.data.data) {
          const userData = resp?.data?.data;
          console.log("🚀 ~ signIn ~ userData:", userData);

          dispatch(
            setUser({
              userId: resp?.data?.doctorId
                ? resp?.data?.doctorId
                : userData?._id,
              authority: userData.role
                ? userData.role?.roleName?.toLowerCase()
                : "guest",
              email:
                userData?.email || userData?.phone
                  ? userData.email || userData?.phone
                  : "test@gmail.com",
              userName: userData?.name ? userData.name : "",
              permissions: userData?.role?.assignedPermissions,
            })
          );

          const permissionChecks = getPermissionChecks(userData);
          const redirectUrl = query.get(REDIRECT_URL_KEY);
          const route = getRedirectRoute(permissionChecks);

          navigate(redirectUrl ? redirectUrl : route);
          window.location.reload();
          return {
            status: "success",
            message: "login done",
          };
        }
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
    window.location.reload();
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };

  return {
    authenticated: token && signedIn === true && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
