import api from "./api/api";

export function getAllTags(data) {
  return api.post("admin/tags/all", data);
}

export function getPatientsByTagName(data) {
  return api.post("admin/patient-by-tag", data);
}

export function getTodayBirthDays() {
  return api.get("admin/today-birthday");
}

export function getTomorrowBirthDay() {
  return api.get("admin/tomorrow-birthday");
}

export function getThemeConfig() {
  return api.get("admin/theme-configs");
}

export function updateThemeConfigAPI(id, data) {
  return api.put(`admin/theme-config/${id}`, data);
}
