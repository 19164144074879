import api from "./api/api";

export function getAllLogos() {
  return api.get("admin/logos/all");
}

export function createLogo(data) {
  return api.post("admin/logo", data);
}

export function deleteLogo(id) {
  return api.delete(`admin/logo/${id}`);
}

export function updateLogo(id, data) {
  return api.put(`admin/logo/${id}`, data);
}
